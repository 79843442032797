<template>
  <div class="PrimaryMenuListItem" :class="(isHighlighted)?'highlighted':(isDisabled)?'disabled':''">
    <span v-html="item.text"></span>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PrimaryMenuListItem",
  props: {
    item: Object,
  },
  computed: {
    ...mapState({
      activeConfig: (state) => state.configurations.active,
    }),
    isHighlighted(){
        if(this.activeConfig.id==undefined)return false;
        if(this.activeConfig.id === this.item.id)return true;
        return false;
    },
    isDisabled(){
        if(this.activeConfig.scenes==undefined)return false;
        var t = this.activeConfig.scenes.truck.split('_');
        t = Array.isArray(t)?t[0]:t;
        if(this.item.scenes.truck.startsWith(t))return false;
        // if(this.activeConfig.scenes.truck === this.item.scenes.truck)return false;
        return true;
    }
  },
};
</script>

<style scoped>
.PrimaryMenuListItem {
  width: var(--primarymenu-listitem-width);
  height: var(--primarymenu-listitem-height);
  padding: var(--primarymenu-listitem-padding);
  background: var(--primarymenu-listitem-fallbackbackground);
  background: var(--primarymenu-listitem-background);
  opacity: var(--primarymenu-listitem-opacity);
  color: var(--primarymenu-listitem-color);
  font-family: var(--primarymenu-listitem-font);
  font-size: var(--primarymenu-listitem-fontsize);
  display: flex;
  justify-content: left;
  align-items: center;
  user-select: none;
  cursor: pointer;
  /* transition: all 0.6s ease-in-out; */
}
.highlighted {
  background: var(--primarymenu-listitem-fallbackbackground-highlighted);
  background: var(--primarymenu-listitem-background-highlighted);
}
.disabled {
  background: var(--primarymenu-listitem-fallbackbackground-disabled);
  background: var(--primarymenu-listitem-background-disabled);
}
</style>
