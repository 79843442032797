import {
    createStore
} from 'vuex';
import configurations from './modules/configurations'



export default createStore({
    modules:{
        configurations
    }
});