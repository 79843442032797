<template>
  <div class="VisualCap" :class="side">

  </div>
</template>

<script>
export default {
    name: "VisualCap",
    props:{
        side:String
    }
}
</script>

<style scoped>
.VisualCap{
    width: var(--visualcap-width);
    height: var(--visualcap-height);
    background: var(--primarymenu-listitem-fallbackbackground);
    background: var(--primarymenu-listitem-background);
    opacity: var(--primarymenu-listitem-opacity);
    border: none;
}
.left{
    clip-path: var(--visualcap-clip-left);
}
.right{
    clip-path: var(--visualcap-clip-right);
}
</style>