const state = {
    all:[],
    active:{},
    defaults:{}
}

const getters = {
    allConfigurations:(state) => state.all,
    activeConfiguration: (state) => state.active,
    defaults:(state) => state.defaults,
    getDefaultForType: (state) => (name) => {
        //TODO: check if this is really correct? we're not looking for a named property here
        if (Object.keys(name).length === 0) return null;
        return state.defaults[name];
    }
}

const actions = {
    async loadConfigurations({
            commit
        }, file = 'data/configurations.json') {
        fetch(file)
        .then(response => {
            return response.json();
        })
        .then(c =>{
            commit('setConfigurations',c.configurations);
            commit('setDefaults', c.defaults);
        })
    },
    activateConfiguration({commit},config){
        commit('setActive',config)
    },
    clearActivateConfiguration({
        commit
    }) {
        commit('setActive', {})
    }
}

const mutations = {
    setConfigurations: (state, configurations) => (state.all = configurations),
    setActive: (state, configuration) => (state.active = configuration),
    setDefaults: (state, defaults) => (state.defaults = defaults)
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}