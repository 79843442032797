<template>
  <div>
    <div class="logo-container">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 113.834 51">
        <g
          id="Hexagon_Agility_Logo"
          data-name="Hexagon Agility Logo"
          transform="translate(-68 -130)"
        >
          <g id="Gruppe_7" data-name="Gruppe 7" transform="translate(68 130)">
            <g id="Gruppe_6" data-name="Gruppe 6" transform="translate(0 0)">
              <g
                id="Gruppe_4"
                data-name="Gruppe 4"
                transform="translate(10.146 17.534)"
              >
                <path
                  id="Pfad_17"
                  data-name="Pfad 17"
                  d="M211.194,91.392V88.729h6.845v6.008a8.08,8.08,0,0,1-6.713,3.147c-4.666,0-8.21-3.235-8.21-7.968a8.02,8.02,0,0,1,8.21-7.967,8.3,8.3,0,0,1,6.471,3.037l-2.179,1.893a5.677,5.677,0,0,0-4.292-2.179,4.935,4.935,0,0,0-4.842,5.216c0,3.081,1.914,5.216,4.842,5.216a5.28,5.28,0,0,0,3.565-1.431V91.392Z"
                  transform="translate(-147.025 -81.95)"
                  fill="#00616f"
                />
                <path
                  id="Pfad_18"
                  data-name="Pfad 18"
                  d="M252.574,97.885a7.968,7.968,0,1,1,8.055-7.968A7.949,7.949,0,0,1,252.574,97.885Zm0-13.183c-2.729,0-4.71,2.289-4.71,5.216s1.981,5.216,4.71,5.216c2.707,0,4.688-2.289,4.688-5.216S255.28,84.7,252.574,84.7Z"
                  transform="translate(-171.772 -81.95)"
                  fill="#00616f"
                />
                <path
                  id="Pfad_19"
                  data-name="Pfad 19"
                  d="M299.273,98.014l-7.065-9.64v9.64h-3.147V82.607h2.773l7.131,9.662V82.607h3.148V98.014Z"
                  transform="translate(-198.424 -82.343)"
                  fill="#00616f"
                />
                <path
                  id="Pfad_20"
                  data-name="Pfad 20"
                  d="M73.374,98.014v-6.6H66.837v6.6H63.57V82.607h3.267v6.141h6.537V82.607h3.271V98.014Z"
                  transform="translate(-63.57 -82.343)"
                  fill="#00616f"
                />
                <path
                  id="Pfad_21"
                  data-name="Pfad 21"
                  d="M101.025,98.031V82.624H111.2v2.663h-6.937v3.477H111.2v2.663h-6.937v3.94H111.2v2.663Z"
                  transform="translate(-85.97 -82.353)"
                  fill="#00616f"
                />
                <path
                  id="Pfad_22"
                  data-name="Pfad 22"
                  d="M139.417,98.012l-3.456-5.282-3.478,5.282h-3.852L134,90.176,128.852,82.6h3.962l3.235,5,3.258-5h3.852l-5.151,7.528,5.371,7.88Z"
                  transform="translate(-102.48 -82.341)"
                  fill="#00616f"
                />
                <path
                  id="Pfad_23"
                  data-name="Pfad 23"
                  d="M178.465,98.014l-.978-2.862h-5.41l-1.021,2.862H167.6l6.213-15.407h2.52l5.713,15.407Zm-5.475-5.436h3.628l-1.76-5.172h-.021Z"
                  transform="translate(-125.786 -82.343)"
                  fill="#00616f"
                />
              </g>
              <g id="Gruppe_5" data-name="Gruppe 5">
                <path
                  id="Pfad_24"
                  data-name="Pfad 24"
                  d="M58.889,85.486,41.6,74.762V52.9L58.889,42.172l6.877,4.265,3.1-1.921-9.977-6.186-20.56,12.75v25.5l20.56,12.75,9.977-6.186-3.1-1.921Z"
                  transform="translate(-38.329 -38.329)"
                  fill="#02b0a4"
                />
                <path
                  id="Pfad_25"
                  data-name="Pfad 25"
                  d="M87.961,52.9V51.079l20.56-12.75,20.56,12.75V52.9h-3.269L108.521,42.173,91.232,52.9Zm0,21.866v1.816l20.56,12.75,20.56-12.75V74.762h-3.269L108.521,85.485,91.232,74.762Z"
                  transform="translate(-68.011 -38.329)"
                  fill="#049099"
                />
              </g>
            </g>
            <path
              id="Pfad_26"
              data-name="Pfad 26"
              d="M201.467,136.975l-.456-1.525H198.49l-.476,1.525H196.4l2.9-8.216h1.175l2.663,8.216Zm-2.552-2.9h1.691l-.82-2.758h-.01Zm8.355-.422v-1.42h3.149v3.2a3.545,3.545,0,0,1-3.089,1.679c-2.147,0-3.777-1.725-3.777-4.249a4,4,0,0,1,3.777-4.249,3.637,3.637,0,0,1,2.977,1.619l-1,1.009a2.516,2.516,0,0,0-1.975-1.162c-1.286,0-2.228,1.221-2.228,2.782,0,1.643.881,2.782,2.228,2.782a2.264,2.264,0,0,0,1.641-.763v-1.232Zm4.608,3.321V128.76h1.448v8.216Zm2.914,0V128.76h1.448v6.8h2.906v1.42Zm5.185,0V128.76h1.448v8.216Zm5.449-6.8v6.8h-1.448v-6.8H222.3v-1.42h4.81v1.42Zm4.4,6.8v-3.439l-2.258-4.777h1.8l1.225,2.958h.021l1.337-2.958h1.691l-2.37,4.824v3.392Z"
              transform="translate(-132.865 -92.327)"
              fill="#00616f"
            />
          </g>
        </g>
      </svg>
    </div>
    <div class="orientation-hint" v-if="showOrientationHint">
      Please change orientation to portrait on mobile.
    </div>
    <div class="canvascontainer">
      <PlayCanvas
        @appCreated="appCreated"
        @appProgress="appProgress"
        ref="pcapp"
      />
    </div>
    <!-- Left primary menu: configurations and trucks -->
    <transition name="menu">
      <div class="PrimaryMenu" v-if="showPrimaryMenu">
        <VisualCap side="left" />
        <PrimaryMenuListItem
          v-for="config in configurations"
          :item="config"
          :key="config.id"
          @click="setActive(config)"
        />
        <div class="TruckSwitch">
          <TruckSwitchButton trucktype="daycab" @doactivate="setActive" />
          <TruckSwitchButton trucktype="sleeper" @doactivate="setActive" />
        </div>
      </div>
    </transition>
    <!-- Right secondary menu: infos display -->
    <transition name="menu">
      <div class="SecondaryMenu" v-if="showSecondaryMenu">
        <VisualCap side="right" />
        <SecondaryMenuListItem
          v-for="item in activeConfig.infos"
          :item="item"
          :key="item"
        />
        <div class="SpacerSecondaryMenu"></div>
      </div>
    </transition>
    <!-- Bottom middle tertiary menu: fullscreen, extra features -->
    <div class="TertiaryMenu">
      <transition-group name="thirdmenu">
        <!-- Fullview -->
        <div class="tertiaryMenuButton" @click="toggleMenu" key="fullview">
          <!-- show  button -->
          <div v-if="!showMenu" class="tertiaryMenuButtonIcon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 51 51"
            >
              <g
                id="Gruppe_33"
                data-name="Gruppe 33"
                transform="translate(-530 -783)"
              >
                <rect
                  id="Rechteck_25"
                  data-name="Rechteck 25"
                  width="51"
                  height="51"
                  transform="translate(530 783)"
                  fill="none"
                />
                <g
                  id="Gruppe_32"
                  data-name="Gruppe 32"
                  transform="translate(-37.607 0.472)"
                >
                  <path
                    id="Pfad_37"
                    data-name="Pfad 37"
                    d="M2333.371,1828.269l-10.085,10.085,10.085,10.085"
                    transform="translate(3533.593 468.311) rotate(135)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="3"
                  />
                  <path
                    id="Pfad_38"
                    data-name="Pfad 38"
                    d="M10.085,0,0,10.085,10.085,20.17"
                    transform="translate(589 798.659) rotate(-45)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="3"
                  />
                </g>
              </g>
            </svg>
          </div>
          <!-- hide button -->
          <div v-if="showMenu" class="tertiaryMenuButtonIcon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 51 51"
            >
              <g
                id="Gruppe_34"
                data-name="Gruppe 34"
                transform="translate(-572 -783)"
              >
                <rect
                  id="Rechteck_25"
                  data-name="Rechteck 25"
                  width="51"
                  height="51"
                  transform="translate(572 783)"
                  fill="none"
                />
                <path
                  id="Pfad_37"
                  data-name="Pfad 37"
                  d="M2333.371,1828.269l-10.085,10.085,10.085,10.085"
                  transform="translate(-2357.593 1164.661) rotate(-45)"
                  fill="none"
                  stroke="#fff"
                  stroke-width="3"
                />
                <path
                  id="Pfad_38"
                  data-name="Pfad 38"
                  d="M10.085,0,0,10.085,10.085,20.17"
                  transform="translate(617.393 802.369) rotate(135)"
                  fill="none"
                  stroke="#fff"
                  stroke-width="3"
                />
              </g>
            </svg>
          </div>
        </div>
        <!-- Wings open -->
        <div
          class="tertiaryMenuButton"
          v-if="hasWings"
          @click="toggleWings"
          key="wings"
        >
          <!-- close icon -->
          <div v-if="wingsAreOpen" class="tertiaryMenuButtonIcon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 51 51"
            >
              <g
                id="Gruppe_65"
                data-name="Gruppe 65"
                transform="translate(-470 -783)"
              >
                <g
                  id="Gruppe_60"
                  data-name="Gruppe 60"
                  transform="translate(-102)"
                >
                  <rect
                    id="Rechteck_25"
                    data-name="Rechteck 25"
                    width="51"
                    height="51"
                    transform="translate(572 783)"
                    fill="none"
                  />
                </g>
                <path
                  id="Pfad_86"
                  data-name="Pfad 86"
                  d="M18.316,10.341l-6.3-3.636,1.757-.765L20,9.552Z"
                  transform="translate(483 794)"
                  fill="#fff"
                />
                <path
                  id="Pfad_87"
                  data-name="Pfad 87"
                  d="M2.943-.319,1.242.45l6.3,3.636,1.633-.794Z"
                  transform="translate(483 794)"
                  fill="#fff"
                />
                <path
                  id="Pfad_88"
                  data-name="Pfad 88"
                  d="M0,22.358l17.078,9.86V12.5L0,2.637Z"
                  transform="translate(483 794)"
                  fill="#fff"
                />
                <path
                  id="Pfad_89"
                  data-name="Pfad 89"
                  d="M19.579,12.5V32.219l1.753-.813.009-19.725Z"
                  transform="translate(483 794)"
                  fill="#fff"
                />
              </g>
            </svg>
          </div>
          <!-- open icon -->
          <div v-if="!wingsAreOpen" class="tertiaryMenuButtonIcon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 51 51"
            >
              <g
                id="Gruppe_63"
                data-name="Gruppe 63"
                transform="translate(1084 1219)"
              >
                <g
                  id="Gruppe_58"
                  data-name="Gruppe 58"
                  transform="translate(-1656 -2002)"
                >
                  <rect
                    id="Rechteck_25"
                    data-name="Rechteck 25"
                    width="51"
                    height="51"
                    transform="translate(572 783)"
                    fill="none"
                  />
                </g>
                <g
                  id="Gruppe_59"
                  data-name="Gruppe 59"
                  transform="translate(-1071 -1210)"
                >
                  <path
                    id="Pfad_81"
                    data-name="Pfad 81"
                    d="M18.329,12.528l-4.642-2.68,8.482.465Z"
                    fill="#fff"
                  />
                  <path
                    id="Pfad_82"
                    data-name="Pfad 82"
                    d="M5.911,0,1.256,2.671l5.61,3.238Z"
                    fill="#fff"
                  />
                  <path
                    id="Pfad_83"
                    data-name="Pfad 83"
                    d="M9.593,7.12l11.673.64L8.509.411Z"
                    fill="#fff"
                  />
                  <path
                    id="Pfad_84"
                    data-name="Pfad 84"
                    d="M0,24.553l17.078,9.86V14.693L0,4.832Z"
                    fill="#fff"
                  />
                  <path
                    id="Pfad_85"
                    data-name="Pfad 85"
                    d="M19.579,14.693V34.414l5.6-3.23.01-19.726Z"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import PrimaryMenuListItem from "./components/PrimaryMenuListItem.vue";
import SecondaryMenuListItem from "./components/SecondaryMenuListItem.vue";
import TruckSwitchButton from "./components/TruckSwitchButton.vue";
import VisualCap from "./components/VisualCap.vue";
import PlayCanvas from "./components/Playcanvas.vue";

export default {
  name: "App",
  components: {
    PrimaryMenuListItem,
    SecondaryMenuListItem,
    TruckSwitchButton,
    VisualCap,
    PlayCanvas,
  },
  data: () => {
    return {
      showMenu: true,
      hasWings: false,
      wingsAreOpen: true,
      appLoaded: false,
      appPercent: 0.0,
      showOrientationHint: false,
      minHeight: 700,
    };
  },
  methods: {
    ...mapActions("configurations", [
      "loadConfigurations",
      "activateConfiguration",
      "clearActivateConfiguration",
    ]),

    setActive(c) {
      this.activateConfiguration(c);
    },
    resetConfig() {
      this.clearActivateConfiguration();
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    toggleWings() {
      //this.wingsAreOpen = !this.wingsAreOpen;
      this.app.fire("wings:animate", !this.wingsAreOpen);
    },
    appCreated(app) {
      this.app = app;
      this.app.on(
        "wings:areopen",
        function (areOpen) {
          this.wingsAreOpen = areOpen;
        },
        this
      );
    },
    appProgress(perc) {
      // console.log("loading progress "+perc);
      this.appPercent = perc;
      if (perc == 1) this.appLoaded = true;
    },
    doLoadApp() {
      this.$refs.pcapp.createApp();
    },
    handleOrientationChange(e) {
      var w, h, a;
      if (!e) {
        w = document.documentElement.clientWidth;
        h = document.documentElement.clientHeight;
      } else {
        h = document.documentElement.clientWidth;
        w = document.documentElement.clientHeight;
      }
      a = w / h;
      this.showOrientationHint = a > 1.0 && h < this.minHeight;
    },
  },
  computed: {
    ...mapState({
      configurations: (state) => state.configurations.all,
      activeConfig: (state) => state.configurations.active,
    }),
    showSecondaryMenu() {
      return (
        this.showMenu &&
        this.activeConfig &&
        this.activeConfig.infos &&
        this.activeConfig.infos.length > 0
      );
    },
    showPrimaryMenu() {
      return this.showMenu && this.appLoaded;
    },
  },
  created() {
    this.loadConfigurations();
    // this.loadConfigurations('data/testing.json');
  },
  mounted() {
    window.addEventListener("orientationchange", this.handleOrientationChange);
    this.handleOrientationChange();
    if (!this.app) this.doLoadApp();
  },
  watch: {
    activeConfig: function (n) {
      this.wingsAreOpen = true;
      this.hasWings = n.scenes.cab.indexOf("wings") > -1;
    },
  },
};
</script>

<style scoped>
.logo-container {
  position: absolute;
  top: var(--logo-top);
  left: var(--logo-left);
  width: var(--logo-width);
  height: var(--logo-height);
  z-index: 10;
}
.orientation-hint {
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  color: #000;
  background: rgba(255, 255, 255, 0.97);
  display: flex;
  justify-content: center;
  align-items: center;
}

.PrimaryMenu {
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.SecondaryMenu {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.SpacerSecondaryMenu {
  width: var(--truckswitch-width);
  height: var(--truckswitch-height);
  background: var(--primarymenu-listitem-fallbackbackground);
  background: var(--primarymenu-listitem-background);
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.TertiaryMenu {
  margin: 0;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  display: flex;
  flex-flow: row;
}
.TruckSwitch {
  width: var(--truckswitch-width);
  height: var(--truckswitch-height);
  background: var(--primarymenu-listitem-fallbackbackground-highlighted);
  background: var(--primarymenu-listitem-background-highlighted);
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.tertiaryMenuButton {
  width: var(--tertiary-button-width);
  height: var(--tertiary-button-height);
  background: var(--tertiary-button-background);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: var(--tertiary-button-margin);
  transition: var(--tertiary-button-transition);
}
.tertiaryMenuButtonIcon {
  width: 100%;
  height: 100%;
}
/* Transitions */
.menu-enter-active,
.menu-leave-active {
  transition: var(--menu-transition);
}
.menu-enter-from,
.menu-leave-to {
  transform: var(--menu-transition-transform);
}
.thirdmenu-enter-active {
  transition: var(--menu-transition);
}
.thirdmenu-leave-active {
  transition: var(--menu-transition-fast);
  display: none;
}
.thirdmenu-enter-from,
.thirdmenu-leave-to {
  opacity: 0;
}

/* PlayCanvas */
.canvascontainer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 0;
}
</style>
