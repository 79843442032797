<template>
  <div class="pc-canvas">
    <transition name="splash">
      <div class="pc-splash" :style="{opacity: preloadOpacity}" v-if="!pcappstarted">{{percentLoaded}}%</div>
    </transition>
    <!-- <div v-if="!pcappstarted" class="pc-splash">Loading</div> -->
    <!-- this is the main canvas for the pc app -->
    <canvas
      :style="{display : pcappstarted?'block':'none'}"
      tabindex="0"
      onselectstart="return false;"
      ref="pccanvas"
      class="pc-canvas"
    ></canvas>
  </div>
</template>

<script>
import * as pc from "playcanvas";
import { mapState } from "vuex";

export default {
  name: "PlayCanvas",
  emits: {
    appCreated: null,
    appProgress: null,
  },
  props: {},
  data: () => {
    return {
      pcappstarted: false,
      //app: null,
      devices: {},
      errorMsg: "",
      percent_loaded:0,
    };
  },
  computed: {
    ...mapState({
      activeConfig: (state) => state.configurations.active,
    }),
    percentLoaded(){
      return Math.round(this.percent_loaded*100.0);
    },
    preloadOpacity(){
      return 1.0-this.percent_loaded;
    }
  },
  methods: {
    createApp() {
      this.createInputDevices();
      try {
        this.app = new pc.Application(this.$refs.pccanvas, {
          elementInput: this.devices.elementInput,
          keyboard: this.devices.keyboard,
          mouse: this.devices.mouse,
          gamepads: this.devices.gamepads,
          touch: this.devices.touch,
          graphicsDeviceOptions: window.CONTEXT_OPTIONS,
          assetPrefix: window.ASSET_PREFIX || "",
          scriptPrefix: window.SCRIPT_PREFIX || "",
          scriptsOrder: window.SCRIPTS || [],
        });
        this.$emit("appCreated", this.app);
        this.app.on(
          "start",
          function() {
            this.pcappstarted = true;
          }.bind(this)
        );
        this.app.on('preload:progress',(p) =>{this.$emit("appProgress",p);this.percent_loaded=p;},this);
        this.app.configure(
          window.CONFIG_FILENAME,
          function(err) {
            if (err) {
              this.errorMsg = err;
              return;
            }
            var self = this;
            setTimeout(function() {
              self.reflow();
              window.addEventListener("resize", self.reflow, false);
              window.addEventListener("orientationchange", self.reflow, false);
              self.app.preload(function(err) {
                if (err) {
                  self.errorMsg = err;
                  return;
                }
                self.app.loadScene(window.SCENE_PATH, function(err) {
                  if (err) {
                    self.errorMsg = err;
                  }
                  self.app.start();
                });
              });
            });
          }.bind(this)
        );
      } catch (e) {
        this.errorMsg = "error with webGL ";
      }
    },
    createInputDevices: function() {
      var canvas = this.$refs.pccanvas;
      this.devices = {
        elementInput: new pc.ElementInput(canvas, {
          useMouse: window.INPUT_SETTINGS.useMouse,
          useTouch: window.INPUT_SETTINGS.useTouch,
        }),
        keyboard: window.INPUT_SETTINGS.useKeyboard
          ? new pc.Keyboard(window)
          : null,
        mouse: window.INPUT_SETTINGS.useMouse ? new pc.Mouse(canvas) : null,
        gamepads: window.INPUT_SETTINGS.useGamepads ? new pc.GamePads() : null,
        touch:
          window.INPUT_SETTINGS.useTouch && pc.platform.touch
            ? new pc.TouchDevice(canvas)
            : null,
      };
    },
    loadModules(modules, urlPrefix, doneCallback) {
      // check for wasm module support
      function wasmSupported() {
        try {
          if (
            typeof WebAssembly === "object" &&
            typeof WebAssembly.instantiate === "function"
          ) {
            const module = new WebAssembly.Module(
              Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00)
            );
            if (module instanceof WebAssembly.Module)
              return (
                new WebAssembly.Instance(module) instanceof WebAssembly.Instance
              );
          }
        } catch (e) {
          console.error("Error with wasm" + e);
        }
        return false;
      }
      // load a script
      function loadScriptAsync(url, doneCallback) {
        var tag = document.createElement("script");
        tag.onload = function() {
          doneCallback();
        };
        tag.onerror = function() {
          throw new Error("failed to load " + url);
        };
        tag.async = true;
        tag.src = url;
        document.head.appendChild(tag);
      }
      // load and initialize a wasm module
      function loadWasmModuleAsync(moduleName, jsUrl, binaryUrl, doneCallback) {
        loadScriptAsync(jsUrl, function() {
          var lib = window[moduleName];
          window[moduleName + "Lib"] = lib;
          lib({
            locateFile: function() {
              return binaryUrl;
            },
          }).then(function(instance) {
            window[moduleName] = instance;
            doneCallback();
          });
        });
      }
      if (typeof modules === "undefined" || modules.length === 0) {
        // caller may depend on callback behaviour being async
        setTimeout(doneCallback);
      } else {
        var asyncCounter = modules.length;
        var asyncCallback = function() {
          asyncCounter--;
          if (asyncCounter === 0) {
            doneCallback();
          }
        };

        var wasm = wasmSupported();
        modules.forEach(function(m) {
          if (
            !Object.prototype.hasOwnProperty.call(m, "preload") ||
            m.preload
          ) {
            if (wasm) {
              loadWasmModuleAsync(
                m.moduleName,
                urlPrefix + m.glueUrl,
                urlPrefix + m.wasmUrl,
                asyncCallback
              );
            } else {
              if (!m.fallbackUrl) {
                throw new Error(
                  "wasm not supported and no fallback supplied for module " +
                    m.moduleName
                );
              }
              loadWasmModuleAsync(
                m.moduleName,
                urlPrefix + m.fallbackUrl,
                "",
                asyncCallback
              );
            }
          } else {
            asyncCallback();
          }
        });
      }
    },
    reflow() {
      var canvas = this.$refs.pccanvas;
      this.app.resizeCanvas(canvas.width, canvas.height);
      canvas.style.width = "";
      canvas.style.height = "";
    },
  },
  mounted() {
    window.pc = pc;
  },
  watch:{
    activeConfig:function(n){
      // console.log("new active config value!"+JSON.stringify(n.scenes));
      if(this.app)this.app.fire('scenario:set',n.scenes);
    }
  }
};
</script>

<style scoped>
.pc-canvas {
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
canvas:focus {
  outline: none;
}
.pc-splash {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--pccanvas-splash-backgroundcolor, transparent);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--loading-font);
  font-size: var(--loading-font-size);
  color: var(--loading-color);
}
.splash-enter-active,
.splash-leave-active {
  transition: var(--splash-fade-duration);
}
.splash-enter-from,
.splash-leave-to {
  opacity: 0;
}
</style>
