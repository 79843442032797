<template>
  <div class="SecondaryMenuListItem">
    <div class="toprow">
      <div class="icon">
        <!-- 
          Icons are type dependant. Include this in every svg: 
         v-if="item.type === 'capacity'"
        -->
        <!-- CAPACITY -->
        <svg
          v-if="item.type === 'capacity'"
          id="Gruppe_43"
          data-name="Gruppe 43"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="60"
          height="40"
          viewBox="0 0 60 40"
        >
          <defs>
            <clipPath id="clip-path">
              <rect
                id="Rechteck_36"
                data-name="Rechteck 36"
                width="60"
                height="40"
                fill="none"
              />
            </clipPath>
          </defs>
          <g id="Gruppe_38" data-name="Gruppe 38" clip-path="url(#clip-path)">
            <path
              id="Pfad_59"
              data-name="Pfad 59"
              d="M38.933,18.972,28.6,26.707a4.089,4.089,0,1,0,5.789,4.344Z"
              fill="#fff"
            />
            <path
              id="Pfad_60"
              data-name="Pfad 60"
              d="M51.709,22.21A22.471,22.471,0,0,0,7.983,32.5l.2,1,3.5-.711-.2-1a18.894,18.894,0,0,1,36.767-8.651,18.709,18.709,0,0,1,.265,8.581l-.2,1,3.507.7.2-1a22.249,22.249,0,0,0-.316-10.206"
              fill="#fff"
            />
          </g>
        </svg>
        <!-- RANGE -->
        <svg
        v-if="item.type === 'range'"
          id="Gruppe_44"
          data-name="Gruppe 44"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="60"
          height="40"
          viewBox="0 0 60 40"
        >
          <defs>
            <clipPath id="clip-path">
              <rect
                id="Rechteck_38"
                data-name="Rechteck 38"
                width="60"
                height="40"
                fill="none"
              />
            </clipPath>
          </defs>
          <path
            id="Pfad_61"
            data-name="Pfad 61"
            d="M7.637,31.927H2.994L40.361,8.074h.929Z"
            fill="#fff"
          />
          <path
            id="Pfad_62"
            data-name="Pfad 62"
            d="M44.785,31.927H40.142L57.078,8.074h.929Z"
            transform="translate(-1)"
            fill="#fff"
          />
          <path
            id="Pfad_63"
            data-name="Pfad 63"
            d="M40.568,17.385l3.354-3.625H42.144l-3.9,3.625Z"
            transform="translate(-0.691 -0.368)"
            fill="#fff"
          />
          <path
            id="Pfad_64"
            data-name="Pfad 64"
            d="M44.14,11.9h1.5l1.8-1.943H46.228Z"
            transform="translate(-0.765 -0.255)"
            fill="#fff"
          />
          <path
            id="Pfad_65"
            data-name="Pfad 65"
            d="M48.255,8.074l-1.03.958H48.3l.887-.958Z"
            transform="translate(-0.8 -0.202)"
            fill="#fff"
          />
          <path
            id="Pfad_66"
            data-name="Pfad 66"
            d="M25.065,29.651l-3.52,3.275h4.643l3.031-3.275Z"
            transform="translate(-0.404 -0.786)"
            fill="#fff"
          />
          <path
            id="Pfad_67"
            data-name="Pfad 67"
            d="M32.656,25.936l5.334-5.765H35.253l-6.2,5.765Z"
            transform="translate(-0.558 -0.572)"
            fill="#fff"
          />
          <g id="Gruppe_41" data-name="Gruppe 41">
            <g id="Gruppe_40" data-name="Gruppe 40" clip-path="url(#clip-path)">
              <rect
                id="Rechteck_37"
                data-name="Rechteck 37"
                width="59.016"
                height="39.024"
                transform="translate(0.492 0.488)"
                fill="none"
              />
            </g>
          </g>
        </svg>

        <!-- WEIGHT -->
        <svg
        v-if="item.type === 'weight'"
          id="Gruppe_46"
          data-name="Gruppe 46"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="60"
          height="40"
          viewBox="0 0 60 40"
        >
          <defs>
            <clipPath id="clip-path">
              <rect
                id="Rechteck_39"
                data-name="Rechteck 39"
                width="60"
                height="40"
                fill="none"
              />
            </clipPath>
          </defs>
          <g id="Gruppe_45" data-name="Gruppe 45" clip-path="url(#clip-path)">
            <path
              id="Pfad_68"
              data-name="Pfad 68"
              d="M41.417,17.191a3.717,3.717,0,0,0-3.348-2.5h-3.5a5.482,5.482,0,1,0-9.137,0h-3.5a3.736,3.736,0,0,0-3.348,2.5L14.628,31.6a2.431,2.431,0,0,0,2.359,2.5H43.012a2.431,2.431,0,0,0,2.359-2.5ZM30,8.661a3.01,3.01,0,1,1-3.01,3.01A3.013,3.013,0,0,1,30,8.661"
              fill="#fff"
            />
          </g>
        </svg>

        <!-- SIZE -->
        <svg
        v-if="item.type === 'size'"
          id="Gruppe_48"
          data-name="Gruppe 48"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="60"
          height="40"
          viewBox="0 0 60 40"
        >
          <defs>
            <clipPath id="clip-path">
              <rect
                id="Rechteck_40"
                data-name="Rechteck 40"
                width="60"
                height="40"
                fill="none"
              />
            </clipPath>
          </defs>
          <g id="Gruppe_47" data-name="Gruppe 47" clip-path="url(#clip-path)">
            <path
              id="Pfad_69"
              data-name="Pfad 69"
              d="M45.534,7.429l1.545,3.409-.784.355-1.544-3.41-1.336.606.789,1.743-.783.355-.79-1.743-1.35.61,1.545,3.41-.783.355L40.5,9.71l-1.336.6.79,1.744-.783.355-.79-1.744-1.35.612,1.544,3.409-.783.354-1.545-3.408-1.336.6.79,1.743-.783.355-.79-1.743-1.35.611,1.545,3.41-.784.354-1.544-3.409-1.336.605.79,1.744-.784.355-.789-1.744-1.35.612,1.544,3.409-.783.355L27.74,15.49l-1.336.6.79,1.743-.783.355-.79-1.743-1.35.612,1.544,3.409-.783.355-1.544-3.41-1.336.606.789,1.742-.783.356-.79-1.745-1.35.613L21.563,22.4l-.783.356-1.545-3.409-1.336.6.79,1.743-.783.354-.79-1.743-1.35.613,1.544,3.408-.783.356-1.544-3.41-1.337.606.79,1.743-.783.354-.79-1.743-1.35.611,1.545,3.41-.784.354L10.73,23.2,9.394,23.8l.79,1.744L9.4,25.9l-.789-1.744-1.35.612,1.544,3.41-.783.354L6.477,25.122,4.534,26,8.763,35.34l46.7-21.158-4.23-9.337Zm5.411,4.279a1.108,1.108,0,1,1,.552-1.466,1.107,1.107,0,0,1-.552,1.466"
              fill="#fff"
            />
          </g>
        </svg>
      </div>
      <div class="value">{{ item.value }}</div>
    </div>
    <div class="bottomrow">
      <div class="description">{{ item.description }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SecondaryMenuListItem",
  props: {
    item: Object,
  },
};
</script>

<style scoped>
.SecondaryMenuListItem {
  width: var(--secondary-listitem-width);
  height: var(--secondary-listitem-height);
  padding: var(--secondary-listitem-padding);
  background: var(--secondary-listitem-fallbackbackground);
  background: var(--secondary-listitem-background);
  opacity: var(--secondary-listitem-opacity);
  color: var(--secondary-listitem-color);
  display: flex;
  justify-content: space-between;
  /* align-items: stretch; */
  flex-flow: column;
  user-select: none;
  cursor: default;
}
.toprow {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.icon {
  width: var(--secondary-listitem-icon-width);
  height: var(--secondary-listitem-icon-height);
  display: flex;
  justify-content: left;
  align-items: center;
}
.value {
  font-family: var(--secondary-listitem-value-font);
  font-size: var(--secondary-listitem-value-fontsize);
  white-space: nowrap;
}
.description {
  font-family: var(--secondary-listitem-description-font);
  font-size: var(--secondary-listitem-description-fontsize);
  white-space: nowrap;
}
</style>
