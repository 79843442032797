<template>
  <div class="TruckSwitchButton" v-if="myConfig" @click="setActive">
    <div class="icon" v-if="myConfig.scenes.truck === 'daycab'" :class="isDisabled?'inactive':'active'">
      <svg
        id="Daycab"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 58.212 26.542"
      >
        <ellipse
          id="Ellipse_5"
          data-name="Ellipse 5"
          cx="4.208"
          cy="4.208"
          rx="4.208"
          ry="4.208"
          transform="translate(12.854 18.126)"
          fill="#fff"
        />
        <ellipse
          id="Ellipse_4"
          data-name="Ellipse 4"
          cx="4.208"
          cy="4.208"
          rx="4.208"
          ry="4.208"
          transform="translate(44.834 18.126)"
          fill="#fff"
        />
        <path
          id="Pfad_28"
          data-name="Pfad 28"
          d="M963.59,437.635v18.038H929.783v4.61h58.212v-5.172l-1.726-.7-1.258-5.137-8.922-1.393-3.084-7.3Z"
          transform="translate(-929.783 -437.635)"
          fill="#fff"
        />
        <ellipse
          id="Ellipse_7"
          data-name="Ellipse 7"
          cx="4.208"
          cy="4.208"
          rx="4.208"
          ry="4.208"
          transform="translate(2.755 18.126)"
          fill="#fff"
        />
      </svg>
    </div>
    <div class="icon" v-if="myConfig.scenes.truck === 'sleeper'" :class="isDisabled?'inactive':'active'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 67.046 25.929"
      >
        <g id="Sleeper">
          <ellipse
            id="Ellipse_1"
            data-name="Ellipse 1"
            cx="3.959"
            cy="3.959"
            rx="3.959"
            ry="3.959"
            transform="translate(3.245 18.01)"
            fill="#fff"
          />
          <ellipse
            id="Ellipse_2"
            data-name="Ellipse 2"
            cx="3.959"
            cy="3.959"
            rx="3.959"
            ry="3.959"
            transform="translate(13.85 18.01)"
            fill="#fff"
          />
          <ellipse
            id="Ellipse_3"
            data-name="Ellipse 3"
            cx="3.959"
            cy="3.959"
            rx="3.959"
            ry="3.959"
            transform="translate(53.982 18.01)"
            fill="#fff"
          />
          <path
            id="Pfad_27"
            data-name="Pfad 27"
            d="M958.61,437.635v18.038H924.534v4.61H991.58v-5.172l-1.726-.7-1.258-5.137-6.217-1.373-3.084-7.3Z"
            transform="translate(-924.534 -437.635)"
            fill="#fff"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "TruckSwitchButton",
  props: {
    trucktype: String,
  },
  emits: ["doactivate"],
  methods: {
    setActive() {
      this.$emit("doactivate", this.myConfig);
    },
  },
  computed: {
    ...mapState({
      activeConfig: (state) => state.configurations.active,
    }),
    ...mapGetters("configurations", ["getDefaultForType"]),
    myConfig() {
      return this.getDefaultForType(this.trucktype);
    },
    isDisabled() {
      if (this.activeConfig.scenes === undefined) return false;
      var t = this.activeConfig.scenes.truck.split('_');
      t = Array.isArray(t)?t[0]:t;
      if (this.myConfig.scenes.truck.startsWith(t))
        return false;
      return true;
    },
  },
};
</script>

<style scoped>
.TruckSwitchButton {
  width: var(--truckswitch-button-width);
  height: var(--truckswitch-button-height);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--truckswitch-color);
  user-select: none;
  cursor: pointer;
  
}
.icon{
  transition: opacity 0.4s ease-in-out;
  width: var(--truckswitch-icon-width);
}
.active {
  opacity: 1.0;
}
.inactive {
  opacity: 0.35;
}
</style>
